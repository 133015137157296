@import '../../../../css/variables.scss';

.body-container.welcome_homepage {
    padding: 0;

    .wrapper {
        width: 1281px;
    }

    &.accessibility {

        .projectsCont {
            background: white;
        }

        img {
            filter: contrast(200%);
        }

    }

    
}


.projectsCont {

    background: #F4F4F4;
    padding: 50px 0 0;
    color: $black;

    a {
        text-decoration: none !important;

    }

    h1 {
        color: $black;
        text-align: center;
        font-size: 26px;
        font-family: $fontMedium;
        letter-spacing: -2px;
        margin: 0 0 30px 0;
    }
    
    

    .projectsFlex {

        
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .projectHome {

            cursor: default;

            .dataLine {

                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 20px 0 50px 0;

                h2 {
                    color: $black;
                    font-size: 22px;
                    font-family: $fontMedium;
                    letter-spacing: -1px;
                    margin: 0px 0 0 0;
    
                    small {
                        font-family: $fontLight;
                        font-size: 22px;
                    }
                }

                .fakeBtn {
                    
                    color: $black;
                    font-size: 18px;
                    font-family: $fontRegular;
                    letter-spacing: -1px;
                    background: white;
                    
                    border: 1px solid #DEDEDE;
                    border-radius: 5px;
                    padding: 10px 50px;

                    opacity: .6;
                    
                }

            }

            &.link {
                cursor: pointer;

                &:hover, &:focus {
                    
                    .fakeBtn {
                        background: $black;
                        color: white;
                    }

                }

                .fakeBtn {
                    opacity: 1;
                }
            }
            
            figure {

                position: relative;
                margin: 0 0 0 0;

                div {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    p {
                        color: white;
                        padding: 5px 65px;
                        font-size: 36px;
                        background: black;
                        font-family: $fontMedium;
                        letter-spacing: -1px;
                        margin: 0;
                    }
                    

                }

                img {
                    width: 100%;
                    display: block;
                }

            }
            
        }
        
    }

}

.menuHomepage {

    display: flex;
    justify-content: space-between;
    padding: 20px 0;

    .rItems {

        display: flex;
        gap: 80px;
        align-items: center;

        img.logoMenu {
            width: 120px;
        }

        ul {

            display: flex;
            gap: 120px;
            margin: 0;

            li {
                color: $black;
                font-size: 18px;
                font-family: $fontMedium;
                letter-spacing: -1px;

                &:last-child, &:first-child {
                    cursor: pointer;
                }
            }
        }

    }

    .lItems {

        p {
            padding: 5px 0 0 0;
            direction: ltr;
            margin: 0;
            color: $black;
            text-align: center;
            font-size: 31px;
            font-family: $fontMedium;
            letter-spacing: -2px;

            small {
                vertical-align: top;
                display: inline-block;
                margin: 10px 2px 0 0;
            }
        }

    }
    


}

.down {

    padding: 40px 0 30px;
    display: flex;
    justify-content: center;

    .aboutLinks {

        width: 530px;
        border-left: 1px solid #BDBDBD;
        padding-left: 40px;

        display: flex;
        justify-content: space-between;

        .links {

            img.logoOfekSheliNormal {
                width: 108px;
            }

            .allLinks {

                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 10px 0 0 0;


                img {
                    height: 20px;
                }

                p {
                    border-right: 1px solid #252626;
                    margin: 3px 0 0 0;
                    padding: 0px 8px 0 0;
                    line-height: 1;
                    direction: ltr;
                    font-family: $fontMedium;
                    color: $black;
                }

            }

        }
        

        .text {
            font-size: 16px;
            line-height: 1.25;
            width: 350px;
            font-family: $fontRegular;
            color: $black;
        }

    }

    .contactForm {
        width: 530px;
        padding-right: 40px;

        h2 {
            color: $black;
            font-size: 16px;
            font-family: $fontMedium;
            letter-spacing: -1px;
            
            &.sent {
                font-size: 40px;
            }

        }

        .cols {
            padding-left: 0;
            margin: 0 0 10px 0;
        }

        .btnCont {

            button {
                width: 100%;
                border-radius: 20px;
                background: $black;
                padding: 5px 15px;
                border-color: $black;

                &:hover, &:focus {
                    background: black;
                }
            }

        }

        input {
            border-radius: 20px;
            width: 100%;
            color: $black;
            padding: 20px 25px;
            font-family: $fontRegular;
        }
    }

}


/* MOBILE MOBILE!!! */
ul.m_menu {
    padding: 40px 40px;

    color: $black;
    font-size: 20px;
    font-family: $fontMedium;
    letter-spacing: -1px;

    li {
        img {
            width: 30px;
        }

        a {
            color: $black;
            text-decoration: none;
        }

        &:first-child {
            margin: 0 0 50px 0;
        }

        margin: 0 0 10px 0;
    }

}

.body-container.welcome_homepage.mobile {


    padding-top: 92px;

    .wrapper {
        width: 100%;
    }


    .m_menuTop {

        background: rgba($color: white, $alpha: .9);

        z-index: 10;

        display: flex;
        justify-content: space-between;
        align-items: center;

        position: fixed;
        top: 0;
        width: 100%;
        padding: 20px 7%;

        img.menuOpen {
            width: 40px;
        }

        img.logoMenu {
            width: 100px;
        }

        .lItems {

            a {
                color: black;
                text-decoration: none !important;
            }

            p {
                padding: 5px 0 0 0;
                direction: ltr;
                margin: 0;
                color: $black;
                text-align: center;
                font-size: 25px;
                font-family: $fontMedium;
                letter-spacing: -2px;
    
                small {
                    vertical-align: top;
                    display: inline-block;
                    margin: 5px 2px 0 0;
                }
            }
    
        }
    }

    .projectsCont .projectsFlex .projectHome {

        &.link .dataLine .fakeBtn {

            opacity: 1;
        }

        .dataLine {

            display: block;
            text-align: center;
            
            h2 {
                
                padding-bottom: 20px;
            }
    
            .fakeBtn {
                display: inline-block;
                opacity: .3;
            }
    
        }

    } 

    .down {
        display: block;

        .aboutLinks {
            display: block;
            width: 100%;
            border: none;
            text-align: center;
            padding: 0;

            .allLinks {
                justify-content: center;
                gap: 13px;
                margin: 20px 0;
            }

            .text {
                width: 100%;
                padding: 0 10%;
            }

        }

        .contactForm {

            display: block;
            width: 100%;
            padding: 0 10% 40px;

            margin: 50px 0 0 0;

            h2 {

                font-size: 20px;
                text-align: center;
                width: 210px;
                margin: 0 auto 40px;

            }

            .row {

                margin: 0;
                padding: 0;

                .cols {
                    padding: 0;
                }

            }
        }
    }

}