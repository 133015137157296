.body-container.welcome_index {
    padding: 0 !important;
}

.body-container.mobile {
    padding: 58px 0 0 0;
}

.mobile .content-wrapper {
    min-height: initial;
}

.mobile .site-header {
    padding: 10px 10px;
    min-height: initial;
}


.mobile  .site-header .main-menu {
    width: 70%;
}

.mobile .site-header .main-menu a {
    display: inline-block;
    width: 120px;
    vertical-align: middle;
    
}

.mobile  .site-header .main-menu img.mainLogo {
    width: 100%;
}


.mobile .site-header button.MuiButton-root {
    outline: none;
}

.mobile .site-header .openIcon {
    width: 30px;
    margin: 4px 0 0 0;
}

.mobile .site-header .col.open {
    text-align: right;
    padding: 0;
}


.mobile .site-header .openIcon span {
    width: 100%;
    height: 2px;
    margin: 0 0 5px 0;
    display: block;
    background: #888A8B;

}

.mobileDraw.menuRight .MuiPaper-root ul.MuiList-root {
    padding: 30px 0;
}

.mobileDraw.menuRight .MuiPaper-root ul.MuiList-root li {
    text-align: center;
}

.mobileDraw.menuRight .MuiPaper-root ul.MuiList-root li a {
        color: grey;
        text-align: center;
        width: 100%;   
}

.mobileDraw.menuRight .MuiPaper-root ul.MuiList-root li a.is-active {
    font-family: "fb_regular","helvetica","arial";
    color: black;
    
}




.mobileDraw.menuRight .social {
    text-align: center;
    width: 100%;
    padding: 0px 0;
}

.mobileDraw.menuRight .social a {
    display: inline-block;
    margin: 0 8px;
}

.mobileDraw.menuRight .social a img {
    height: 25px;
    vertical-align: middle;
    
}



.mobileDraw.menuRight .social span.sitePhone {
    font-size: 20px;
    color: black;
    display: block;
    padding: 15px 0;
    margin: 0 10px 0px 0;
}


.mobile footer.site-footer section.formSec {
    padding: 40px 20px;
}

.mobile footer.site-footer section.formSec h2 {
    font-size: 18px;
}
.mobile footer.site-footer section.formSec h2 strong {
    font-size: 23px;
    margin: 2px 0;
    display: inline-block;
}

.mobile footer.site-footer .form-wrapper {
    width: 90%;
    padding: 0 5%;
    margin: 30px auto 0;
}

.mobile footer.site-footer .form-wrapper .MuiInput-root.MuiInput-underline {
    margin-bottom: 0;
}

.mobile footer.site-footer .form-wrapper .MuiFormControl-root {
    width: 100%;
    position: relative;
    margin: 0px 0 0 0;
}

.mobile footer.site-footer .form-wrapper .MuiFormLabel-root.MuiInputLabel-root {
    width: 100%;
}

.mobile .form-wrapper .MuiFormLabel-root.Mui-focused,
.mobile .form-wrapper .MuiFormLabel-root.MuiFormLabel-filled {
    right: -70px !important;
}

.mobile footer.site-footer .form-wrapper .selectFormGroup {
    width: 100%;
    margin: 18px 0px 50px 10px;
}
.mobile footer.site-footer .form-wrapper .selectFormGroup select {
    width: 100%;
    background-color: transparent;
}

footer.site-footer .downText {
    background: #E9E9E9;
}

footer.site-footer .downText .text {
    margin: 0 0 30px 0;
}


footer.site-footer .downText .text a {
    color: black;
    text-decoration: underline;
}

footer.site-footer .beitShemeshFooter h2 a {
    color: black;
}



.mobile .credit {
    text-align: center;

}
.mobile .credit img {
    width: 150px;
}

.mobile .projectView .infoProject section.plans img.lupa {
    display: none;
    width: 40px !important;
    bottom: 40px;
    margin: 0;
    /* opacity: 1;
    filter: alpha(opacity=100); */
}


.mobile .AccPage .columns {
    
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
}

.mobile .AccPage img.logo {
    width: 50px;
}